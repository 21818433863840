import { css } from "aphrodite";
import PropTypes from "prop-types";

import Col from "components/Common/Col";
import { Grid, Row } from "components/Common/FlexGrid";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  fullWidthContentContainer: {},
  fullWidthRow: {
    [ScreenSizes.mdAndBelow]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  fullWidthCol: {
    [ScreenSizes.mdAndBelow]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  contentContainer: {
    // TODO: I think this should be inheriting from gStyles.contentContainer
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "2rem",
    padding: "1rem",

    [ScreenSizes.mdOnly]: {
      padding: "1.5rem",
    },
    [ScreenSizes.lgOnly]: {
      padding: "2.2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      padding: "3rem",
    },
  },
  noHorizontalPaddingContentContainer: {
    paddingLeft: 0,
    paddingRight: 0,

    [ScreenSizes.mdOnly]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [ScreenSizes.lgOnly]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  noVerticalPaddingContentContainer: {
    paddingTop: 0,
    paddingBottom: 0,

    [ScreenSizes.mdOnly]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.lgOnly]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  mediumVerticalPaddingContentContainer: {
    paddingTop: "1rem",
    paddingBottom: "1rem",

    [ScreenSizes.mdOnly]: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    [ScreenSizes.lgOnly]: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
  },
  siteHeaderPaddingContentContainer: {
    ...gStyles.siteHeaderPaddingTop,
  },
  contentToEdge: {
    width: "100%",
  },
  divider: {
    borderTop: `1px ${colours.lightGreyBorder} solid`,
  },
  title: {
    ...gStyles.avalonSemiBold,
    width: "100%",
    padding: 0,
    margin: "2rem auto",
    textAlign: "center",
    fontSize: "1.5em",
    fontWeight: 600,
    color: colours.bodyText,
  },
};

const ContentContainer = (props) => {
  const {
    children,
    style,
    title,
    showDivider,
    contentStyle,
    overrideStyles,
    fullWidth,
    noVerticalPadding,
    noHorizontalPadding,
    mediumVerticalPadding,
    siteHeaderPadding,
    dataId,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { styles: overrideStylesheet } = useStyles(overrideStyles);

  return (
    <div
      className={css(
        styles.container,
        style,
        showDivider && styles.divider,
        overrideStylesheet && overrideStylesheet.container
      )}
      data-testid={dataId}
      data-id={dataId}
    >
      <Grid
        className={css(
          styles.contentContainer,
          fullWidth && styles.fullWidthContentContainer,
          noHorizontalPadding && styles.noHorizontalPaddingContentContainer,
          noVerticalPadding && styles.noVerticalPaddingContentContainer,
          mediumVerticalPadding && styles.mediumVerticalPaddingContentContainer,
          siteHeaderPadding && styles.siteHeaderPaddingContentContainer,
          contentStyle,
          overrideStylesheet && overrideStylesheet.contentContainer
        )}
      >
        <Row className={css(styles.row, fullWidth && styles.fullWidthRow)}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xlOffset={0}
            className={css(fullWidth && styles.fullWidthCol)}
          >
            {title && (
              <h2 className={css(styles.title)} key={"content-container-title"}>
                {title}
              </h2>
            )}
            {children}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  title: PropTypes.string,
  showDivider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noVerticalPadding: PropTypes.bool,
  dataId: PropTypes.string,
  noHorizontalPadding: PropTypes.bool,
  siteHeaderPadding: PropTypes.bool,
  mediumVerticalPadding: PropTypes.bool,
  overrideStyles: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

ContentContainer.defaultProps = {
  children: null,
  style: null,
  contentStyle: null,
  title: null,
  showDivider: false,
  fullWidth: false,
  dataId: null,
  noVerticalPadding: false,
  noHorizontalPadding: false,
  siteHeaderPadding: false,
  overrideStyles: null,
  mediumVerticalPadding: false,
};

export default ContentContainer;
